import React from "react";
import { useFetchSingleUser } from "../../../../data/users/use-fetch-single-user";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const Profile: React.FC = () => {


    // Fetch user data using the fetched userId
    const { data: user, isLoading, error } = useFetchSingleUser();
    const navigate = useNavigate();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

   /* const handleReplenish = (plan: string) => {
        navigate('/checkout', { state: { plan } });
    };*/

   
    if (!user){
        return null;
    }

    // Determine the current plan
    const getPlan = () => {
        return user.subscriptionStatus === 'Active' ? 
            'Lawlyfy Chat Tokens' : 
            'Platinum Plan';
    };

    return (
        <div className='bg-blue-100 rounded-3xl p-8 max-h-[85%] flex flex-col overflow-auto'>
            <div className ='w-full flex items-center justify-between mb-4 md:mb-8'>
            <h1 className='font-bold text-xl '>User Profile</h1>
            <button 
                            className="py-2 px-4 hover:text-blue-500 duration-300"
                            onClick={() => navigate(`/wakili`)}
                            >

                        <FontAwesomeIcon icon={faArrowAltCircleLeft} className="inline mr-2"/> Back
                        </button>
                        </div>
            {user ? (
                <div className='flex flex-col md:flex-row justify-between gap-2 text-xs'>
                    <div className='space-y-4 w-full md:w-2/3 my-2 md:my-4'>
                    <div className='w-full md:w-4/5 grid grid-cols-1 md:grid-cols-2 gap-4'>
                                <p><span className='font-semibold'>Name: </span>{user.name}</p>
                                <p><span className='font-semibold'>Email: </span>{user.email}</p>
                                <p><span className='font-semibold'>Subscription Status: </span>{user.subscriptionStatus}</p>
                                <p><span className='font-semibold'>Subscription Plan: </span>{user.subscriptionPlan}</p>
                                <p><span className='font-semibold'>Total Wakili Chat Messages: </span>{user.nonSpecificChatMessages}</p>
                                <p><span className='font-semibold'>Total CWMD Messages: </span>{user.specificChatMessages}</p>
                           
                            </div>

                        <div className='w-full md:w-4/5 shadow-md py-6 rounded-3xl px-4'>
                            <div className='space-y-3'>
                                <div className='flex justify-between items-center'>
                                    <p className='font-semibold'>Wakili Chat #: </p>
                                    <p>{user.nonSpecificChatMessagesSinceActivation ?? 0} / 200</p>
                                   {/*<button
                                        className="ml-4 bg-green-500 text-sm text-white rounded-2xl py-1 px-3 hover:bg-green-600"
                                        onClick={() => handleReplenish('Lawlyfy Chat Tokens')}
                                    >
                                        Replenish
                                    </button> */} 
                                </div>
                                <div className='relative w-full h-4 bg-white rounded-full overflow-hidden'>
                                <div
                                        className='absolute top-0 left-0 h-full bg-gradient-to-r from-linearBlue1 to-linearBlue2'
                                        style={{ width: `${((user.nonSpecificChatMessagesSinceActivation ?? 0) / 200) * 100}%` }}
                                    ></div>
                                </div>
                            </div>

                            {/* Chat With My Docs Section */}
                            <div className='space-y-4 mt-8'>
                                <div className='flex justify-between items-center'>
                                    <p className='font-semibold'>Chat With My Docs #: </p>
                                    <p>{user.specificChatMessagesSinceActivation ?? 0} / 100</p>
                                   {/*  <button
                                        className="ml-4 bg-green-500 text-sm text-white rounded-2xl py-1 px-3 hover:bg-green-600"
                                        onClick={() => handleReplenish('Chat With My Docs Tokens')}
                                    >
                                        Replenish
                                    </button>*/}
                                </div>
                                <div className='relative w-full h-4 bg-white rounded-full overflow-hidden'>
                                <div
                                        className='absolute top-0 left-0 h-full bg-gradient-to-r from-linearBlue1 to-linearBlue2'
                                        style={{ width: `${((user.specificChatMessagesSinceActivation ?? 0) / 100) * 100}%` }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>No user data found</div>
            )}
        </div>
    );
};

export default Profile;
