import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createOrder } from '../../data/pesapal/api';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography, Paper, CircularProgress } from '@mui/material';
import { useFetchSingleUser } from '../../data/users/use-fetch-single-user';

interface CheckoutProps {
  plan: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  amount: number;
  account_number?: number; 
}

const Checkout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { plan } = (location.state || {}) as CheckoutProps;
  const { data: user, isLoading: userLoading } = useFetchSingleUser();

  useEffect(() => {
    if (!userLoading && user) {
      setIsReady(true);
    }
  }, [userLoading, user]);


  if (userLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }


  const amount = (() => {
    if (user?.Role === 'admin') {
      return 1;
    }
    switch (plan) {
      case 'Silver': return 1299;
      case 'Gold': return 1599;
      case 'Platinum': return 1999;
      case 'Lawlyfy Chat Tokens': 
      case 'Chat With My Docs Tokens': return 260;
      default: return 1299;
    }
  })();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobileNumber: Yup.string()
      .matches(/^(?:\+254|0)[17]\d{8}$/, 'Must be a valid Kenyan phone number')
      .required('Mobile number is required'),
    amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
  
  });
  const userId = user?.id;

  const handleCheckout = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    setLoading(true);
    setError(null);
    try {
      const order = await createOrder({
        userId,
        plan,
        amount: values.amount,
        currency: 'KES',
        description: plan,
        billing_address: {
          email_address: values.email,
          phone_number: values.mobileNumber,
          first_name: values.firstName,
          last_name: values.lastName,
        },
        account_number: user.account_number, 
      });

      setRedirectUrl(order.redirect_url);
    } catch (err) {
      setError('Failed to create order. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="w-full md:w-2/3 mx-auto py-8 md:py-16">
      {error && <Typography color="error">{error}</Typography>}

      {!redirectUrl ? (
        userLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Grid container spacing={2}>
           <Grid item xs={12} md={6}>
              <Paper className="md:min-h-[80vh] p-4">
                <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "16px" }}>
                  MPesa Payment Details
                </Typography>

                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: user.email, 
                    mobileNumber: "",
                    amount: amount,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleCheckout}
                >
                  {({ errors, touched, isSubmitting, values, handleChange }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} sx={{ paddingBlock: "16px" }}>
                          <TextField
                            fullWidth
                            name="firstName"
                            label="First Name"
                            value={values.firstName}
                            onChange={handleChange}
                            error={touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name="lastName"
                            label="Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            error={touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="mobileNumber"
                            label="Mobile (Mpesa) Number"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            error={touched.mobileNumber && !!errors.mobileNumber}
                            helperText={touched.mobileNumber && errors.mobileNumber}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="amount"
                            label="Amount"
                            type="number"
                            value={values.amount}
                            InputProps={{ readOnly: true }}
                            error={touched.amount && !!errors.amount}
                            helperText={touched.amount && errors.amount}
                          />
                        </Grid>

                        <Typography className="m-4 px-4 py-6 text-xs text-gray-500 leading-[32px]">
                          Please note, you will receive an STK push notification on your registered M-Pesa number shortly. Kindly follow the prompts on your phone to complete the payment.
                        </Typography>

                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || loading}
                            sx={{ paddingBlock: "16px" }}
                            fullWidth
                          >
                            {loading ? "Processing..." : "Proceed to Payment"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className="md:min-h-[80vh] p-4">
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{plan} Plan</Typography>

                <div className="flex gap-2 text-sm my-4 font-semibold text-gray-700">
                  <div className="border-r border-gray-300 pr-2 mr-2">
                    KES {amount}
                  </div>
                  <div className="border-r border-gray-300 pr-2 mr-2">
                    Unlimited access
                  </div>
                  <div>Month</div>
                </div>

                <div className="rounded-2xl p-4 bg-gray-200 text-sm md:mt-8">
                  <div className="bg-white rounded-2xl mx-4 px-4 py-3">Paid monthly</div>

                  <div className="flex justify-between py-4 px-6 font-semibold text-black border-b border-dotted border-gray-500">
                    <div>1 user per Month</div>
                    <div>KES {amount}</div>
                  </div>

                  <div className="my-4 flex justify-between py-4 px-6 font-semibold text-black border-b border-dotted border-gray-500">
                    <div className="flex flex-col">
                      <div className="font-semibold">Updates</div>
                      <div className="mt-3 font-light">Frequent updates for all current services</div>
                    </div>
                  </div>

                  <div className="my-4 py-4 px-6 text-black">
                    <div>
                      <div className="font-semibold">Services Offered</div>

                      <ul className="font-light my-4 space-y-3">
                        {plan === 'Lawlyfy Chat Tokens' && (
                          <li>*<span className="font-semibold"> Wakili Chat :</span> 200 Chats + Citations</li>
                        )}
                        {plan === 'Chat With My Docs Tokens' && (
                          <li>*<span className="font-semibold"> Chat With My Docs :</span> 100 Chats + Citations</li>
                        )}
                        {plan !== 'Lawlyfy Chat Tokens' && plan !== 'Chat With My Docs Tokens' && (
                          <>
                            <li>*<span className="font-semibold"> Semantic AI :</span> Up to 500 Queries</li>
                            <li>*<span className="font-semibold"> Keyword Search :</span> Unlimited Searches</li>
                            {(plan === 'Silver' || plan === 'Platinum') && (
                              <li>*<span className="font-semibold"> Wakili Chat :</span> 200 Chats + Citations</li>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        )
      ) : (
        redirectUrl && (
          window.location.href = redirectUrl
        )
      )}
    </div>
  );
};

export default Checkout;
