import { useState, useEffect } from 'react';
import { getTransactionStatus } from '../../data/pesapal/api';
import { Box, Typography, Paper, Grid, Button, CircularProgress } from '@mui/material';

interface TransactionStatus {
  payment_method: string;
  amount: number;
  payment_status_description: string;
  message: string;
  confirmation_code: string;
  merchant_reference: string;
}

export default function Payment(): JSX.Element {
  const [status, setStatus] = useState<TransactionStatus | string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTransactionStatus = async (): Promise<void> => {
      try {
        setLoading(true);
        const result = await getTransactionStatus();
        setStatus(result);
      } catch (err) {
        setStatus('Error fetching transaction status.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionStatus();
  }, []);

  return (
    <Box sx={{ padding: 4, maxWidth: '800px', margin: 'auto' }}>
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Checking transaction status...
          </Typography>
        </Box>
      ) : typeof status === 'string' ? (
        <Paper sx={{ padding: 4, textAlign: 'center', backgroundColor: '#fdecea' }}>
          <Typography variant="h6" color="error">
            {status}
          </Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.location.href = '/'}>
            Retry
          </Button>
        </Paper>
      ) : status ? (
        <Paper sx={{ padding: 4 }}>
         
          <Grid container spacing={2}>
           
            <Grid item xs={12}>
              <Typography variant="body1"> {status.payment_status_description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"> {status.message}</Typography>
            </Grid>
           
           
            
            <div className='flex Lighter_Shade rounded-2xl items-center px-2'>

              <button   className="w-32 text-white font-bold p-2 flex items-center" onClick={() => window.location.href = '/wakili'}> Back to Lawlyfy</button>
          </div>
          </Grid>
        </Paper>
      ) : null}
    </Box>
  );
}